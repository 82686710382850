import { network } from "./network";

export type ProductList = {
    id: string,
    title: string,
    catagory: string,
    slug: string
}
export class Session {
    productList: ProductList[];
    constructor() {
        this.productList = []
    }

    async getProductList(): Promise<ProductList[] | []> {
        const data = localStorage.getItem("productList");
        this.productList = JSON.parse(data!) as ProductList[]
        if (!this.productList) {

            this.productList = await network.AJAX();

        }
        return this.productList;
    }
}
export const session = new Session();