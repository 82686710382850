// var rtime;
// var timeout = false;
// var delta = 200;
// $(window).resize(function () {
//     rtime = new Date();
//     if (timeout === false) {
//         timeout = true;
//         setTimeout(resizeend, delta);
//     }
// });

import { autobind } from "./decorators/autobind";

// function resizeend() {
//     if (new Date() - rtime < delta) {
//         setTimeout(resizeend, delta);
//     } else {
//         timeout = false;
//         alert('Done resizing');
//     }
// }

export default class WaitUntilFinished {

    private currentFunction: Function;

    constructor(
        private delayTime = 100,
        private eventsStopped = false,
        private startTime = new Date,

    ) {
        this.currentFunction = () => { };
    }

    public beginWatch(st: Date, fn: Function) {

        this.startTime = st;
        this.currentFunction = fn;

        if (!this.eventsStopped) {
            this.eventsStopped = true;
            this.waitOnfinish();
        }
    }

    public convertToTitleCase() {

    }

    @autobind
    private waitOnfinish() {
        if (Number(new Date()) - Number(this.startTime) < this.delayTime) {
            setTimeout(this.waitOnfinish, this.delayTime);
        } else {
            this.eventsStopped = false;
            this.currentFunction();
        }
    }


}


