class State {
    private static instance: State;
    private _searchTerm: string;
    private _searchData: [];

    constructor() { }

    static getInstance() {
        if (!this.instance)
            this.instance = new State();

        return this.instance
    }


    public get searchTerm(): string {
        return this._searchTerm;
    }
    public set searchTerm(value: string) {
        this._searchTerm = value;
    }

    public get searchData(): [] {
        return this._searchData;
    }
    public set searchData(data: []) {
        this._searchData = data;
    }




}
export const state = State.getInstance();
