import { menuView } from "../view/menu";
// import { MainContentView } from "../view/main-content-view";
// import { network } from "../util/network";
// import { state } from "../state/project-state";
import { location } from "../util/location";
import { productView } from "../view/product";
import { outOfArea } from "../view/out-of-area";
import { autobind } from "../decorators/autobind";
import { network } from "../util/network";
import { view } from "../view/view";
// import slugify from "slugify";
import { qrMenuView } from "../view/qr-menu";
import { alerts } from "../util/alerts";
import { searchView } from "../view/searchView";
import { state } from "../util/state";
import { error } from "console";
import { loginController } from "./login-controller";





export default class Controller {

    private currentReadData;
    private delayTimer;

    constructor() {


        const route = window.location.href.split('/').pop();
        console.log("hi");

        if (route === "login") {
            loginController.addValidationHandlers();
            return;
        }

        this.mainAppStart();
        this.delayTimer = null;

    }



    async mainAppStart() {

        const prodContainer = document.querySelector('.product__container') as HTMLDivElement;
        if (prodContainer) {
            prodContainer.style.opacity = '1';
        }
        const local = await location.isInCorrectArea()

        if (local === 'noLocation') {
            outOfArea.renderOutOfArea(false);
            return
        }
        if (!local) {
            outOfArea.renderOutOfArea(true);
            return
        }

        menuView.addHandlerHamMenu(this.menuController);

        await menuView.renderMenuItems(this.subMenuController);
        qrMenuView.addHandlerQRMenu(this.qrController);
        qrMenuView.addQRBtnHandler(this.qrSubmitBtnController);

        searchView.addHandlerToSearchInput(this.searchController);
        view.spinner('.product__container');

    }

    @autobind
    menuController(target) {

        if (state.searchTerm) {
            searchView.resetPreviousData(state.searchTerm, state.searchData);
        }
        qrMenuView.stopQR();

        if (target.classList.contains('arrow-btn')) {
            menuView.render('qrSlide');
            return;
        }
        menuView.render('menu');
    };

    @autobind
    qrController() {
        menuView.render('qr');

        qrMenuView.renderQR(this.qrReadController);


    };

    @autobind
    async qrReadController(slug: string) {

        try {

            qrMenuView.spinner('.qr-screen-container');
            const data = await network.AJAX(slug);
            console.log(data);

            if (!data) {

                qrMenuView.deactivateBtn();
                return;
            }

            this.currentReadData = data;
            alerts.showAlert('success', 'QR Read Successfully!', 3500)
            qrMenuView.setLabelAndActivateBtn(data.title);

        } catch (err) {
            alerts.showAlert('error', "Unknown QR Code!<br>Please try again!");
            setTimeout(() => {

                qrMenuView.deactivateBtn();
            }, alerts.alertOpenTime);
        }
        qrMenuView.spinner('.qr-screen-container');
    };

    @autobind
    qrSubmitBtnController() {
        if (!this.currentReadData) return

        qrMenuView.stopQR();
        qrMenuView.deactivateBtn();
        const productmenuEl = document.getElementById(this.currentReadData.slug) as HTMLLIElement;
        if (!productmenuEl) return;
        this.subMenuController(productmenuEl);
    }

    @autobind
    subMenuController(target?: HTMLLIElement) {

        const productSlug = window.location.href.split('/').pop();


        // Close the menu
        setTimeout(() => {
            menuView.render(false);
        }, 200);
        if (target.id === productSlug) return

        menuView.changeActiveMenuItem(target, productSlug!);

        this.renderNewProduct(target.id)
    };

    @autobind
    searchController(input: string) {

        searchView.showClear(input.length > 0 ? true : false);
        if (this.delayTimer) clearTimeout(this.delayTimer);
        if (!input || input == '') {
            return searchView.closeSearchDropDown();
        }

        this.delayTimer = setTimeout(async () => {

            // if the current input is the same as state, do not contact server.

            state.searchTerm = input;

            try {
                state.searchData = await network.AJAX('0', input);
            } catch (err) {
                alerts.showAlert('error', 'Unable to connect to server.<br>Please try again later.');
            }

            searchView.renderResults(state.searchData, this.searchItemController);
        }, 1250);
    }

    @autobind
    searchItemController(slug: string) {
        const chosenElement = menuView.getMenuElementBySlug(slug) as HTMLLIElement;
        searchView.showClear(false);

        this.subMenuController(chosenElement);
    }

    async renderNewProduct(id: string) {

        productView.spinner('.container')

        window.scrollTo({ top: 0, behavior: 'smooth' });

        const data = await network.AJAX(id);

        setTimeout(() => {
            productView.render(data);

        }, 500);
        window.history.pushState("", data.title, `${data.slug}`)
    }



}
