
import { autobind } from "../decorators/autobind";
import MenuView from "./menu";
import { Product } from './view';
import { alerts } from '../util/alerts';
import { state } from "../util/state";


export class SearchView extends MenuView {

    protected searchInput: HTMLInputElement | undefined;
    protected searchInputValue: string;
    protected searchClearButton: HTMLImageElement;
    protected searchResultsContainer: HTMLDivElement;
    protected searchContainer: HTMLDivElement;
    protected closeHandle: HTMLDivElement;
    private searchData: Product[] = [];

    private controllerFn;

    constructor() {
        super();
        this.searchInput = undefined;
        this.searchInputValue = '';
    }

    @autobind
    public addHandlerToSearchInput(handler: Function) {
        this.initializeSearch();

        ['keyup'].forEach((event) => {
            this.searchInput!.addEventListener(event, () => {
                handler(this.searchInput.value);
            })
        });

        ['click', 'touch'].forEach((event) => {


            document.addEventListener(event, (e) => {

                // if user clicks outside of the search area and results area, close search
                if ((<HTMLElement>e.target).closest('.search--container') === null) {
                    this.hideSearchDetails();
                }
            });

            this.closeHandle.addEventListener(event, () => {
                this.hideSearchDetails();
            })

            this.searchClearButton.addEventListener(event, () => {
                this.clearSearchInput();
            })
        })
    };




    public renderResults(data?: Product[], resultControllerFn?: Function) {

        this.controllerFn = resultControllerFn;
        this.searchData = data;

        // 1) show the popup


        this.searchResultsContainer.classList.remove('hide');
        setTimeout(() => {
            this.searchResultsContainer.classList.add('open');
        }, 50);

        // 2) set search results
        if (this.searchContainer.contains(this.searchResultsContainer)) {
            (<HTMLDivElement>this.searchResultsContainer.firstChild).innerHTML = '';
            if (this.searchData.length === 0) {


                // const resultDiv = document.createElement('div');
                // resultDiv.classList.add('result__item');
                // resultDiv.textContent =
                //     `No results found
                // Please try again`;

                // (<HTMLDivElement>this.searchResultsContainer.firstChild).insertAdjacentElement('beforeend', resultDiv);
                const resultDiv = `<div class='result__item'>No results found<br>Please try again</div>`;
                (<HTMLDivElement>this.searchResultsContainer.firstChild).insertAdjacentHTML('beforeend', resultDiv);
                return
            }

            this.searchData.forEach(el => {
                const resultDiv = document.createElement('div');
                resultDiv.classList.add('result__item');
                resultDiv.textContent = el.title;
                resultDiv.id = el.slug;

                (<HTMLDivElement>this.searchResultsContainer.firstChild).insertAdjacentElement('beforeend', resultDiv);

                // addEventListner for result 
                ['click', 'touch'].forEach((event) => {
                    resultDiv.addEventListener(event, () => {
                        resultControllerFn(resultDiv.id)
                    })
                });

                const grandientLine = document.createElement('div');
                grandientLine.classList.add('gradient__line');
                (<HTMLDivElement>this.searchResultsContainer.firstChild).insertAdjacentElement('beforeend', grandientLine);
            });

        };

        // this.closeSearchDropDown();
        // alerts.showAlert('error', `No results for '${this.searchInput.value}.'<br>Please try again!`);
        // this.showNothingFound();



    }

    public showNothingFound() {

    }

    public closeSearchDropDown() {
        this.searchResultsContainer.classList.remove('open');
    }

    public resetPreviousData(term: string, data: []) {

        this.searchData = data;

        setTimeout(() => {
            this.searchInputValue = term;
        }, 200)
    }

    @autobind
    public hideSearchDetails() {

        this.searchResultsContainer.classList.remove('open');
        setTimeout(() => {
            this.searchInput.classList.remove('focus__search-input');
            this.searchInputValue = this.searchInput.value;
            this.searchInput.value = '';
        }, 150)
        this.closeSearchResults();
    }


    public showClear(show: boolean) {
        if (show) {
            this.searchClearButton.classList.add('show');
            setTimeout(() => {
                this.searchClearButton.classList.add('fadeIn');
            }, 200)
            return
        }

        this.searchClearButton.classList.remove('fadeIn');
        setTimeout(() => {
            this.searchClearButton.classList.remove('show');

        }, 200)

    }

    private closeSearchResults() {
        this.searchResultsContainer.classList.remove('open');
        setTimeout(() => {
            this.searchResultsContainer.classList.add('hide');
        }, 500)
    }
    private clearSearchInput() {
        this.searchInput.value = "";
        if (this.searchInputValue) this.searchInputValue = '';
        this.showClear(false);
        this.closeSearchResults();


    }


    protected initializeSearch() {
        this.searchInput = document.querySelector('.search')! as HTMLInputElement;
        this.searchResultsContainer = document.querySelector('.search__results')! as HTMLDivElement;
        this.searchContainer = document.querySelector('.search--container')! as HTMLDivElement;
        this.closeHandle = document.querySelector('.close__handle')! as HTMLDivElement;
        this.searchClearButton = document.querySelector('.search__clear')! as HTMLImageElement;


        // on focus, if there is a value, put it back
        ['focus'].forEach((event) => {


            this.searchInput!.addEventListener(event, () => {



                this.searchInput.classList.add('focus__search-input');

                if (this.searchInputValue != '') {
                    this.searchInput.value = this.searchInputValue;

                    this.showClear(true);
                    if (this.searchData.length != 0) {
                        this.searchResultsContainer.classList.add('open');
                        this.renderResults(this.searchData, this.controllerFn);
                    }
                }
            })
        });




    }
}

export const searchView = new SearchView();