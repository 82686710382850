// import { BASE_URL } from "./config";

import axios from "axios";
import { alerts } from "./alerts";

import { config } from "./config";
// import { Product, state } from "../state/project-state";
// import { data } from "./testData";

export function timeout(s: number): Promise<PromiseRejectedResult> {
    return new Promise(function (_, reject) {
        setTimeout(function () {
            reject(new Error(`Request is taking too long! Timeout after ${s} seconds`));
        })
    })
}



export class Network {
    constructor() { }
    async AJAX(_id?: string, searchTerm?: string) {

        try {
            // 1) if their is an id, get by id
            if (_id && _id !== '0') {

                const res = await axios({
                    method: 'GET',
                    url: config.BASE_URL + `api/v1/products/${_id}`
                });

                return res.data.doc;
            }


            // 2) if there is no id, get all products within list
            if (_id === '0' && searchTerm) {
                const res = await axios({
                    method: 'GET',
                    url: config.BASE_URL + `api/v1/products?search=${searchTerm}&fields=title,catagory,slug`
                });

                return res.data.data.data;
            }

            // What are the different catagories?
            // 1: Get all products
            // 2: Get one product

            // Get list of products
            const res = await axios({
                method: 'GET',
                url: config.BASE_URL + `api/v1/products?fields=title,catagory,slug,metaprop&sort=title,catagory`
            });
            return res.data.data.data

            // 3) if there is a search term in products, return the data from it
            // const res = await axios({
            //     method: 'GET'
            // })
        } catch (err) {
            throw alerts.showAlert("error", "Unable to connect to server!<br>Please try later.");
        }

    }

    async userLoginAJAX(email: string, password: string) {

        try {
            const res = await axios({
                method: 'POST',
                url: 'api/v1/users/login',
                data: {
                    'email': email,
                    'password': password
                }
            });

            if (res) {
                window.location.href = window.location.origin + '/user/backend/';
            }

        } catch (err) {

            throw alerts.showAlert("error", err.response.data.message);
        }
    }
}

export const network = new Network()
