import { autobind } from "../decorators/autobind";
import MenuView from "./menu";
import { MultiFormatReader, BarcodeFormat, BrowserMultiFormatReader } from '@zxing/library';
import { alerts } from "../util/alerts";

export class QRMenuView extends MenuView {

    private QRBtn: HTMLImageElement | undefined;
    private codeReader;
    private qrScannedLabel: HTMLDivElement | undefined;
    private qrSubmitBtn: HTMLDivElement | undefined;
    private currentReadSlug: string | undefined;

    private readQRHanderFunction;



    constructor() {
        super();
        this.QRBtn = undefined;
        this.footerEl = undefined;
        this.qrScannedLabel = document.querySelector('.scanned-qr') as HTMLDivElement;
        this.qrSubmitBtn = document.querySelector('.qr-go') as HTMLDivElement;
        this.codeReader = new BrowserMultiFormatReader();
        this.currentReadSlug = undefined;


    }


    public addHandlerQRMenu(handler: Function): void {
        this.configure();
        this.windowHeightAdjustment();

        ['click', 'touch'].forEach(function (event) {
            [qrMenuView.QRBtn].forEach(function (object: any) {
                object!.addEventListener(event, function (e: Event) {
                    handler(e.target);
                });
            })
        })

    }
    @autobind
    public addQRBtnHandler(handler: Function) {

        ['click', 'touch'].forEach(function (event) {
            qrMenuView.qrSubmitBtn!.addEventListener(event, function () {

                handler();
            })
        })
    }

    public async renderQR(handler: Function): Promise<string | undefined> {

        this.readQRHanderFunction = handler;

        const result = await this.codeReader.listVideoInputDevices()
            .then(async () => {
                this.decodeOnce(this.codeReader, undefined);
                if (!result) return undefined;
                console.log(result);

                return result.toLowerCase();
            })
            .catch(() => {


                return undefined
            });

        return result;


    }
    public stopQR() {
        this.codeReader.reset();
        this.currentReadSlug = '';
    }

    public setLabelAndActivateBtn(title: string) {

        this.qrScannedLabel.textContent = title;
        this.qrSubmitBtn.classList.add('open');
    }

    public deactivateBtn() {
        this.qrScannedLabel.textContent = '';
        this.currentReadSlug = '';
        this.qrSubmitBtn.classList.remove('open');

    }



    protected configure(): void {

        this.QRBtn = document.querySelector('.qr-btn')! as HTMLImageElement;

        if (!this.footerEl) this.footerEl = document.querySelector('footer')!;
    }

    protected decodeOnce(codeReader: BrowserMultiFormatReader, selectedDeviceId: string) {
        codeReader.decodeFromVideoDevice(selectedDeviceId, 'qr-video', (result, err) => {
            if (result && this.currentReadSlug !== result.getText().split('/').pop()) {
                this.currentReadSlug = result.getText().split('/').pop();

                if (this.readQRHanderFunction) this.readQRHanderFunction(this.currentReadSlug);
            }
        })
    }


}

export const qrMenuView = new QRMenuView()