export class Alerts {
    alertOpenTime = 4000;
    openCloseDiration = 500;
    constructor() {

    }

    public hideAlert = () => {
        const el = document.querySelector('.alert');
        if (el) el.parentElement.removeChild(el);
    }

    public showAlert = (type, msg, time = this.alertOpenTime) => {
        this.hideAlert();
        const markup = `<div class="alert alert--${type}">${msg}</div>`;
        document.querySelector('body').insertAdjacentHTML('afterbegin', markup);
        const el = document.querySelector('.alert');
        setTimeout(() => {
            el.classList.add('alert__move');
        }, this.openCloseDiration)
        setTimeout(() => {
            el.classList.remove('alert__move');
        }, time - this.openCloseDiration)
        window.setTimeout(this.hideAlert, time);
    }
}

export const alerts = new Alerts();