
export class Config {

    public readonly BASE_URL = '';
    public readonly LATITUDE_TOP = 32.27978;
    public readonly LATITUDE_BOTTOM = 32.270864;
    public readonly LONGITUDE_EAST = -95.270498;
    public readonly LONGITUDE_WEST = -95.279592;
    public readonly PRODUCT_BODY_BASE_CONTENT = '<div class="body-gradient"></div>';
    public readonly OUT_OF_AREA_IMAGE = 'thankyou.svg';
    public readonly WE_USE_LOCATION_IMAGE = 'sorry.svg';
    public readonly SPINNER = 'spinner';
    public readonly SPINNER_IMAGE = 'siteImages/chakra-spinner4.svg';
    public readonly NO_PRODUCT_CLASS_NAME = 'no-product';
    public readonly NO_PRODUCT_IMG = 'siteImages/welcome_screen.svg'

    // latitude specifies a locations distance north or south of the equator
    // Longitude specifies a location distance east or west of the equator
}
export const config = new Config();