// import { autobind } from "../decorators/autobind";
// import { state } from "../state/project-state";
import { config } from "../util/config";
import View from "./view";
import { Product } from "./view";


export class ProductView extends View {
    protected container: HTMLDivElement | undefined;

    constructor() {
        super();
        this.container = undefined;
    }

    public renderNoProduct() {
        this.init();

        const newDiv = document.createElement('div');
        const newImg = document.createElement('img');
        newDiv.className = config.NO_PRODUCT_CLASS_NAME;
        newImg.src = `/img/${config.NO_PRODUCT_IMG}`;
        newDiv.appendChild(newImg);

        this.currentMarkup = this.container!.innerHTML;

        this.clearBodyContent(this.container!);

        this.container!.insertAdjacentElement('afterbegin', newDiv);
    }

    protected renderElement(html: string, data: any): void {
        this.init();

        this.container!.innerHTML = '';
        this.container!.insertAdjacentHTML('beforeend', html);
        const prodContainer = document.querySelector('.product__container') as HTMLDivElement;

        setTimeout(() => {
            prodContainer.style.opacity = '1';
        }, 500)


        // const container = document.querySelector('.container');
        // container!.innerHTML = html;
    }

    protected generateMarkup(data: Product): string {



        return `
            <div class="body-gradient"></div>
            <div class="product__container">
                <div class="image__container">
                    <img class="product-img" src="/img/products/${data.image}">
                </div>
                
                <h1>${data.title.split(" ").map(el => el[0].toUpperCase() + el.slice(1)).join(' ')}</h1>
                <div class="meta__properties">
                    <p><i>${data.metaProp.map(el => el[0].toUpperCase() + el.slice(1)).join(', ')}</i></p>
                </div>
                <div class="paragraph__container">
                ${data.description.split('\n').map(el =>
            `<p>${el}</p>`
        ).join('')
            }</div>
            </div>`
    }

    protected init() {
        if (!this.container) {
            this.container = document.querySelector('.container')! as HTMLDivElement;
        }

    }




}
export const productView = new ProductView();