import View from "./view";
import { autobind } from "../decorators/autobind";
import WaitUntilFinished from "../helpers";
import { session } from "../util/session";
// import { ProductList } from "../util/session";


// 1) get the data of the products from the server when the 
// 2) manipulate the dom to put the menu together.
// 3) I only want to do this once though 
// There is no need to do this every time the site is reloaded
// session data?

// interface menuItem {
//     title: string,
//     catagory: string,
// }


export default class MenuView extends View {
    protected menuBtn: HTMLButtonElement | undefined;
    protected arrowBtn: HTMLDivElement | undefined;
    protected footerEl: HTMLElement | undefined;
    private waitUntilFinished: WaitUntilFinished;
    // private menuCatagories: menuItem[] | undefined;
    protected menuState: string;
    protected btnState: string;
    protected curOpenSubMenu: HTMLUListElement | undefined;
    protected search: HTMLInputElement | undefined;


    constructor(
    ) {
        super();
        this.footerEl = undefined;
        this.menuBtn = undefined;
        this.waitUntilFinished = new WaitUntilFinished();
        this.arrowBtn = undefined;
        this.curOpenSubMenu = undefined;
        this.search = undefined;
        this.btnState = '';
        this.menuState = 'closed';
    }

    @autobind
    public addHandlerHamMenu(handler: Function) {

        this.configure();
        this.windowHeightAdjustment();

        ['click', 'touch'].forEach((event) => {
            [this.menuBtn, this.arrowBtn].forEach(function (object: any) {
                object!.addEventListener(event, function (e: Event) {
                    handler(e.target);
                });
            })
            // menuView.arrowBtn!.addEventListener(event, function (e: Event) {
            //     handler(e.target)
            // });
        })
        window.addEventListener("resize", this.windowHelper)
    }

    @autobind
    protected windowHelper() {
        this.waitUntilFinished.beginWatch(new Date, this.windowHeightAdjustment);
    }

    @autobind
    protected renderElement(_html: string, data: any): void {

        // const qrSection = document.querySelector('.qr__container') as HTMLDivElement;
        // const menuSection = document.querySelector('.menu__container') as HTMLDivElement;
        const slider = document.querySelector('.slider--container') as HTMLDivElement;
        const qrBtn = document.querySelector('.qr-btn') as HTMLDivElement;
        const transitionTime = data === 'qr' ? 0 : 250;

        if (this.menuState === 'open' && (data === 'qr' || data === 'qrSlide')) {
            slider.classList.toggle('moveOver')
            qrBtn!.classList.toggle('qr__btn-hide')
            this.arrowBtn.classList.toggle('open');
            this.search.classList.toggle('open');
            this.search.classList.toggle('fade-out');
            this.search.textContent = '';
            return
        }

        // Open or close the menu

        this.menuState = this.openCloseMenu();

        if (this.menuState === 'open') {
            if (data === 'menu') {
                slider.classList.remove('moveOver')
                this.arrowBtn.classList.remove('open');
                this.search.classList.add('open');
                this.search.classList.remove('fade-out');
                return
            }
            // data = qr
            slider.classList.add('moveOver')
            this.arrowBtn.classList.add('open');
            qrBtn!.classList.add('qr__btn-hide');
            this.search.classList.remove('open');
            this.search.classList.add('fade-out');
            return
        }

        setTimeout(() => {

            slider.classList.remove('moveOver')
        }, transitionTime)

    }
    public getMenuElementBySlug(slug: string) {

        if (!slug) return;
        return Array
            .from(document.querySelectorAll(`[id=${slug}]`))
            .filter(
                el =>
                    el.classList.contains('inner__li'))[0];
    }

    public changeActiveMenuItem(targetEl: HTMLLIElement, currentSlug: string) {

        const currentElement = this.getMenuElementBySlug(currentSlug);
        if (!currentElement) return;

        setTimeout(() => {

            currentElement?.classList.remove('active');
            targetEl.classList.add('active');
        }, 1000);
    }

    @autobind
    protected openCloseMenu(): string {


        const QRBtn = document.querySelector('.qr-btn')! as HTMLImageElement;
        const menuBtn = document.querySelector('.menu-btn')! as HTMLButtonElement;


        if (this.footerEl!.style.transform !== `translate(0%, 0%)`) {
            this.footerEl!.style.transform = `translate(0%, 0%)`
            QRBtn!.classList.toggle('open');
            menuBtn!.classList.toggle('open');
            this.search!.classList.add('open');
            return 'open'
        }
        this.footerEl!.style.transform = `translate(0, calc(var(--app-height-calc) - 65px))`;
        QRBtn!.classList.toggle('open');
        if (QRBtn!.classList.contains('qr__btn-hide')) QRBtn!.classList.remove('qr__btn-hide');
        menuBtn!.classList.toggle('open');
        this.arrowBtn.classList.remove('open');
        this.search!.classList.remove('open');
        this.close();
        return 'closed'
    }


    public async renderMenuItems(controlFn: Function) {

        const productSlug = window.location.href.split('/').pop();

        const menuHeaders = Array.from(document.querySelectorAll('.main__li'));

        menuHeaders.forEach(el => {
            this.showOrHideSubMenuHandler(el as HTMLLIElement)

            const innerMenu = Array.from(el.children[1].children)

            innerMenu.forEach(el => {
                if (productSlug === el.id) el.classList.add('active');
                this.subMenuHandler(el as HTMLLIElement, controlFn);
            })
        })

    }

    protected configure() {

        session.getProductList();
        this.footerEl = document.querySelector('footer')!;
        this.menuBtn = document.querySelector('.menu-btn')! as HTMLButtonElement;
        this.arrowBtn = document.querySelector('.arrow-btn')! as HTMLImageElement;
        this.search = document.querySelector('.search')! as HTMLInputElement;
        // this.menuEl = document.querySelector('.menu__ul')! as HTMLUListElement;
        // this.renderMenuItems();
    }

    protected windowHeightAdjustment() {
        const doc = document.documentElement;
        doc.style.setProperty('--app-height-calc', `${window.innerHeight}px`);
    }

    protected showOrHideSubMenuHandler(newEl: HTMLLIElement) {
        ['click', 'touch'].forEach(event => {

            newEl.addEventListener(event, (e) => {

                const target = e.target as HTMLElement
                const childUL = newEl.children[1] as HTMLUListElement;
                const headerSpan = newEl.children[0] as HTMLDivElement;

                if (!target.classList.contains('main__li')
                    && !target.classList.contains('menu-header-span'))
                    return


                if (this.curOpenSubMenu && this.curOpenSubMenu != childUL) {
                    this.curOpenSubMenu.parentElement!.children[0].classList.remove("active");
                    this.curOpenSubMenu.style.height = '0px';
                }


                // If there is a height set and its != 0
                if (childUL.style.height && childUL.style.height != '0px'
                    || target.nextElementSibling?.children.length === 0) {
                    childUL.style.height = '0px';
                    headerSpan.classList.remove("active");
                    return
                }
                // else set the height to working height


                // length smaller than calculated menu height, use the smaller height
                headerSpan.classList.add("active");
                childUL.children.length * 44 < this.getSubMenuHeight()
                    ? childUL.style.height = `${childUL.children.length * 44}px`
                    : childUL.style.height = `${this.getSubMenuHeight()}px`;


                this.curOpenSubMenu = childUL;
            })
        })
    };

    protected getSubMenuHeight() {

        const slideContainerheight = document.querySelector('.slider--container')?.clientHeight;
        const searchHeight = document.querySelector('.search')?.clientHeight;
        const menuHeaders = document.querySelectorAll('.main__li');

        const menuHeight = Array.from(menuHeaders)
            .reduce(
                (acc, cur) =>
                    acc > cur.clientHeight
                        ? cur.clientHeight
                        : acc, 200
            )

        return slideContainerheight! - (((menuHeaders.length + 1) * menuHeight - 30) + searchHeight!);

    }

    public subMenuHandler(subMenuEl: HTMLLIElement, controlFn: Function) {

        subMenuEl.addEventListener('click', (e) => {
            const productSlug = window.location.href.split('/').pop();

            // if a new product was selected, change, else do nothing
            if (productSlug != subMenuEl.id) this.transitionOutBody();

            controlFn(e.target as HTMLLIElement);
        })
    }


    protected close(): void {

        const menuItems = document.querySelectorAll('.main__li');

        menuItems.forEach(el => {
            const menu = el.children[1] as HTMLLIElement
            if (menu.style.height != '0')
                menu.style.height = '0';

            const span = el.children[0] as HTMLDivElement;
            span.classList.remove('active')
        })
    }
}

export const menuView = new MenuView();