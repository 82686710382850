import { rejects } from "assert";
import { config } from "./config";

interface geoLocation {
    latitude: number,
    longitude: number
}
export class Location {

    constructor() {

    }
    async isInCorrectArea(): Promise<boolean | "noLocation"> {

        try {
            let geo = this.checkForGeoInCookies();
            if (!geo)
                geo = await this.getGeolocation() as geoLocation;


            if (!geo) throw new Error;

            if (
                geo.latitude < config.LATITUDE_TOP
                && geo.latitude > config.LATITUDE_BOTTOM
                && geo.longitude > config.LONGITUDE_WEST
                && geo.longitude < config.LONGITUDE_EAST
            ) {
                document.cookie = `location = {${String(geo.latitude)},${String(geo.longitude)}}, expires=${new Date(Date.now() + 5 * 60 * 1000).getTime()}`
                return true;
            }
            return false

        } catch (err) {
            return 'noLocation';
        }

    }

    checkForGeoInCookies(): geoLocation | undefined {

        const cookie = decodeURIComponent(document.cookie);
        const currentDate = new Date(Date.now()).getTime();
        const experation = cookie.split(', ').pop()?.split('=').pop();

        console.log(experation)

        if (currentDate <= +experation!) {

            let expression = (<string>cookie.match(/\{(.*?)\}/g)[0]);
            expression = expression.slice(1, expression.length - 1);


            const data = expression.split(',');
            const geo: geoLocation = {
                latitude: +data[0],
                longitude: +data[1]
            }

            return geo
        }
        return undefined
    }

    // This one is really slow
    async getGeolocation(): Promise<geoLocation> {
        const options = {
            enableHighAccuracy: false
        }

        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                position => {
                    const { latitude, longitude } = position.coords as geoLocation;
                    resolve({ latitude, longitude });
                },
                (error) => {
                    reject(error);
                },
                options
            )
        })
    }

    // This one is fast
    async getGeoLocaiton2(): Promise<geoLocation | null> {
        const options = {
            enableHighAccuracy: false
        }
        navigator.geolocation.getCurrentPosition(
            position => {
                const { latitude, longitude } = position.coords as geoLocation;
                return { latitude, longitude }
            },
            (error) => {
                console.log(error)
            },
            options
        )
        return null
    }

}
export const location = new Location();