
export function autobind(_target: any, _methodName: string, descriptor: PropertyDescriptor) {

    const originalMethod = descriptor.value; // this gives us access to the original method
    const adjDescriptor: PropertyDescriptor = {
        configurable: true,

        get() {
            const boundFn = originalMethod.bind(this); // this' gets defined by the original method that defined it
            return boundFn;
        }
    };
    return adjDescriptor;
}
