
import { config } from "../util/config";

// import { state } from "../state/project-state";
export interface Product {
    slug: string,
    title: string,
    image: string,
    description: string,
    metaProp: string[]
}


export default class View {
    currentMarkup: string;

    constructor() {
        this.currentMarkup = '';
    }

    public render(data?: any, render = true) {
        if (!render) return this.close();
        // if (!data) return;
        const html = this.generateMarkup(data);

        this.renderElement(html, data)
    }

    protected close() { }

    protected clearBodyContent(container: HTMLElement) {
        container!.innerHTML = config.PRODUCT_BODY_BASE_CONTENT;
    }

    protected transitionOutBody() {
        const mainContainer = document.querySelector('.container');
        const currentMainContainer = mainContainer?.classList.contains('error__container')
            ? document.querySelector('.error__container') as HTMLDivElement
            : document.querySelector('.product__container') as HTMLDivElement;
        currentMainContainer.style.opacity = '0';
    }

    public spinner(container: string, _main = false) {

        const element = document.querySelector(container) as HTMLElement;
        const spinner = document.querySelector('.spinner') as HTMLDivElement;

        if (!element) return;
        if (spinner?.parentElement === element) {
            spinner.remove();
            return
        }

        this.renderSpinner(element, config.SPINNER, config.SPINNER_IMAGE);
        return
    }

    public checkContainerExistanceAndReturn(containerName: string): HTMLElement {
        let container = document.querySelector(containerName) as HTMLElement;
        if (container) return container

        const mainContainer = document.querySelector('.container');

        container = document.createElement('div');
        container.classList.add('product__container')
        mainContainer?.insertAdjacentElement('afterbegin', container);

        return container
    }

    protected renderSpinner(container: HTMLElement, className: string, img: string) {

        const newDiv = document.createElement('div');
        const newImg = document.createElement('img');
        newDiv.className = className;
        newImg.src = `img/${img}`;
        newDiv.appendChild(newImg);

        this.currentMarkup = container.innerHTML;

        // this.clearBodyContent(container);

        container.insertAdjacentElement('afterbegin', newDiv);

    }

    protected generateMarkup(data: Product): string {

        if (this.currentMarkup != '') return this.currentMarkup;
        return '';
    }

    protected renderElement(_html: string, _data: any) { }
}

export const view = new View()