import { network } from "../util/network";



interface booleanCheck {
    value: boolean
}

export default class LoginController {
    private loginSubmitBtn: HTMLButtonElement;
    private userEmailEl: HTMLInputElement;
    private userPasswordEl: HTMLInputElement;

    private emailIsValid: booleanCheck = { value: false };
    private passIsValid: booleanCheck = { value: false };

    constructor() {
        this.initializeElements();

    }

    private initializeElements() {
        this.loginSubmitBtn = document.querySelector('.login__submit');
        this.userEmailEl = document.querySelector('#email');
        this.userPasswordEl = document.querySelector('#password');

    }

    private login() {
        network.userLoginAJAX(this.userEmailEl.value, this.userPasswordEl.value);

    }

    public addValidationHandlers() {

        this.loginSubmitBtn.addEventListener('click', () => {
            this.login();

        });

        document.addEventListener('keypress', (e) => {
            if (e.key === 'Enter' && !this.loginSubmitBtn.disabled) {
                this.login();
            }
        });

        ['keyup', 'focusout'].forEach((event: string) => {
            this.userEmailEl.addEventListener(event, () => {
                this.validateEmail(this.userEmailEl);
            });

            this.userPasswordEl.addEventListener(event, () => {
                this.validateInput(this.userPasswordEl);
            });

            document.addEventListener(event, (e) => {

                this.checkFormStatus([this.emailIsValid, this.passIsValid]);
            })
        });



    }

    private checkFormStatus(array: booleanCheck[]) {
        this.loginSubmitBtn.disabled = array.some((e) => e.value === false);
    }

    private validateEmail(emailInput: HTMLInputElement) {
        const result = (<boolean>(<unknown>emailInput.value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)));
        return this.validationCheck(result, this.emailIsValid, emailInput)
    }

    private validateInput(inputEl: HTMLInputElement) {
        return this.validationCheck(inputEl.value !== '', this.passIsValid, inputEl)
    }

    private validationCheck(check: boolean, boolEl: booleanCheck, el: HTMLElement) {

        if (check) {
            boolEl.value = true;
            el.classList.remove('is-invalid');
            return true;
        }
        boolEl.value = false;
        el.classList.add('is-invalid');
        return false;
    }

}

export const loginController = new LoginController();