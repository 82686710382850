import View from "./view";
import { config } from "../util/config";


export class OutOfArea extends View {

    constructor() {
        super();
    }


    public renderOutOfArea(isOutOfArea: boolean) {
        const [body] = document.getElementsByTagName('body')
        body.innerHTML = '';

        let image;
        isOutOfArea
            ? image = config.OUT_OF_AREA_IMAGE
            : image = config.WE_USE_LOCATION_IMAGE
        const markup = ` 
        <div>
            <div class="noInArea">
                <img class="thanks" src="./img/siteImages/${image}" />
                <div class="bottom_link">
                    <span>In the mean time...</span>
                    <span>visit our <a class="btm-str-link" href="https://crystalrockshoptx.com/">main site</a>.</span>

                </div>
            </div>
        </div>`;
        body.innerHTML = markup;
    }
}

export const outOfArea = new OutOfArea()